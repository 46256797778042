export interface HeaderItem {
  name: string;
  head?: {
    label?: string;
    items: Array<{
      label?: string;
      url?: string;
      social?: Array<{
        url: string;
        icon: string;
      }>;
    }>;
  };
  table: Array<{
    label?: string;
    url?: string;
    items: Array<{
      label: string;
      url?: string;
      items?: Array<{
        label: string;
        url: string;
      }>;
    }>;
  }>;
}

export const HeaderSocial = [
  {
    url: 'https://www.linkedin.com/company/pinkerton-corporate-risk-management/',
    icon: '/icons/social/linkedin.svg',
  },
  {
    url: 'https://vimeo.com/user168978093',
    icon: '/icons/social/vimeo.svg',
  },
  {
    url: 'https://www.facebook.com/pinkertoncorporate',
    icon: '/icons/social/facebook.svg',
  },
  {
    url: 'https://twitter.com/Pinkerton',
    icon: '/icons/social/twitter-x.svg',
  },
];

export const HeaderItems: HeaderItem[] = [
  {
    name: 'Services',
    table: [
      {
        label: 'Embedded SMEs',
        items: [
          {
            label: 'Overview',
            url: '/services/embedded-smes',
          },
        ],
      },
      {
        label: 'Protection',
        items: [
          {
            label: 'Dedicated protection',
            items: [
              {
                label: 'Onsite Protection',
                url: '/protection/onsite-protection',
              },
              {
                label: 'Executive Protection',
                url: '/protection/executive-protection',
              },
              {
                label: 'High Net Worth Individuals',
                url: '/programs/high-net-worth-individuals-hnwi',
              },
              {
                label: 'Travel Security',
                url: '/programs/corporate-travel-safety-and-security',
              },
            ],
          },
          {
            label: 'Ad hoc protection',
            items: [
              {
                label: 'Response Protection',
                url: '/protection/response-services',
              },
              {
                label: 'Workplace Violence Mitigation',
                url: '/protection/workplace-violence-mitigation',
              },
              {
                label: 'Event Security',
                url: '/advisory/event-security',
              },
            ],
          },
        ],
      },
      {
        label: 'Investigations',
        items: [
          {
            label: 'Practice area',
            items: [
              {
                label: 'Corporate Investigations',
                url: '/investigations/corporate-investigations',
              },
              {
                label: 'Compliance & Ethics',
                url: '/investigations/compliance',
              },
              {
                label: 'Litigation Support',
                url: '/investigations/litigation-support',
              },
              {
                label: 'Threat Management',
                url: '/investigations/threat-assessment-and-management',
              },
              {
                label: 'Threat Analysis',
                url: '/advisory/threat-analysis',
              },
              {
                label: 'Event Security',
                url: '/advisory/event-security',
              },
            ],
          },
          {
            label: 'Reports',
            items: [
              {
                label: 'SCOUT | Due Diligence',
                url: '/investigations/scout-due-diligence',
              },
            ],
          },
        ],
      },
      {
        label: 'Consulting',
        items: [
          {
            label: 'Advisory',
            items: [
              {
                label: 'Security and Risk Consulting',
                url: '/advisory/security-and-risk-consulting',
              },
              {
                label: 'Risk Assessments',
                url: '/advisory/risk-assessments',
              },
              {
                label: 'Enterprise Security Risk Management',
                url: '/advisory/enterprise-security-risk-management',
              },
              {
                label: 'Event Security',
                url: '/advisory/event-security',
              },
            ],
          },
          {
            label: 'Analysis',
            items: [
              {
                label: 'Pinkerton Crime Index',
                url: '/products/pinkerton-crime-index/',
              },
              {
                label: 'Pinkerton Risk Pulse',
                url: '/advisory/pinkerton-risk-pulse',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Industries',
    table: [
      {
        label: 'Industries',
        items: [
          {
            label: 'Healthcare',
            url: '/industries/healthcare',
          },
          {
            label: 'Financial Institutions',
            url: '/industries/financial-institutions',
          },
          {
            label: 'Data Centers',
            url: '/industries/data-centers',
          },
        ],
      },
    ],
  },
  {
    name: 'About Us',
    table: [
      {
        label: 'About',
        items: [
          {
            label: 'Our Approach',
            url: '/our-approach',
          },
          {
            label: 'Our Story',
            url: '/our-story',
          },
          {
            label: 'Press Room',
            url: '/press-room',
          },
        ],
      },
      {
        label: 'Insights',
        items: [
          {
            label: 'Blog',
            url: '/our-insights/blog',
          },
          {
            label: 'Videos',
            url: '/our-insights/video',
          },
          {
            label: 'Briefings',
            url: '/our-insights/analysis',
          },
          {
            label: 'Risk Snapshots',
            url: '/our-insights/risk-snapshots',
          },
        ],
      },
    ],
  },
  {
    name: 'Contact us',
    head: {
      items: [
        {
          label: 'Contact Us',
          url: '/contact-us',
        },
        {
          social: HeaderSocial,
        },
        {
          label: 'Vendor Program',
          url: '/gam',
        },
      ],
    },
    table: [
      {
        label: 'Find Your Pinkerton',
        items: [
          {
            label: 'Find Your Pinkerton',
            url: '/find-your-pinkerton',
          },
          {
            label: 'EMEA',
            url: '/find-your-pinkerton#emea',
          },
          {
            label: 'Asia Pacific',
            url: '/find-your-pinkerton#asia-pacific',
          },
          {
            label: 'India',
            url: '/find-your-pinkerton#india',
          },
          {
            label: 'Latin America',
            url: '/find-your-pinkerton#latin-america',
          },
          {
            label: 'North America',
            url: '/find-your-pinkerton#north-america',
          },
        ],
      },
    ],
  },
];
